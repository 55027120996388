import React from "react"
import { Parallax } from "react-parallax"
import bgImage from "../assets/images/Contact-us-top.jpg"
import { ValidationError, useForm } from "@formspree/react"

const ContactForm = () => {
  const [state, handleSubmit] = useForm("xayvoqqw")
  if (state.succeeded) {
    return (
      <p>
        お問い合わせありがとうございます。もし返信がない場合はお手数ですがTwitter
        @yuya_mstrまで連絡ください。
      </p>
    )
  }
  return (
    <>
      <section>
        <form
          onSubmit={handleSubmit}
          method="POST"
          className="form"
          action="/success"
        >
          <div className="form-control">
            <label htmlFor="name">名前*</label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="おなまえ"
              required
            />
          </div>
          <div className="form-control">
            <label htmlFor="email">Email*</label>
            <input type="email" id="email" name="email" placeholder="E-mail" />
            <ValidationError
              prefix="Email"
              field="email"
              errors={state.errors}
            />

            <div className="error-message">正しい形式で入力してください。</div>
          </div>
          <div>
            <label htmlFor="tel">電話番号</label>
            <input type="tel" name="tel" pattern="\d{2,4}-\d{3,4}-\d{3,4}" />
          </div>
          <div className="form-control message">
            <label htmlFor="message">お問い合わせ内容</label>
            <textarea
              name="message"
              id="message"
              rows="15"
              cols="50"
            ></textarea>
          </div>

          <button
            type="submit"
            disabled={state.submitting}
            className="btn-primary"
          >
            送信
          </button>
        </form>
      </section>
    </>
  )
}
function Contact() {
  return (
    <>
      <Parallax
        bgImage={bgImage}
        bgImageAlt="shopify"
        strength={200}
        blur={{ min: 0, max: 1 }}
      >
        <div className="parallax-box">
          <div className="parallax-info">
            <h1>お問合せ</h1>
            <p>お問合せは下記のフォームよりお願いいたします。</p>
          </div>
        </div>
      </Parallax>
      <section className="contact">
        <ContactForm />
      </section>
    </>
  )
}

export default Contact
